import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loadSpinner, setLoadSpinner] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoadSpinner(true);
      const data = await axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        url: "https://apmcnisiinitiatives.herokuapp.com/api/admin-forgot-password",
        data: {
          email,
        },
      });
      console.log("======", email);
      toast(`${data.data.message}`);
      console.log("mallam000", data);
    } catch (err) {
      // Catch error message
      if (err.response) {
        console.log("mallam111", err.response.data.error);
        toast(`${err.response.data.error}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        console.log("mallam222", err.message);
        setLoadSpinner(false);
      }
    }
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Forgot Password</h1>
                    <p className="text-muted">Enter email to reset password</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </CInputGroup>

                    <CRow>
                      <CCol xs="4">
                        <Link to="#">
                          <CButton
                            color="primary"
                            className="px-4"
                            onClick={submitForm}
                          >
                            Reset{" "}
                            {loadSpinner ? (
                              <Loader
                                type="TailSpin"
                                color="#FFFFFF"
                                height={20}
                                width={20}
                                timeout={3000} //3 secs
                              />
                            ) : (
                              ""
                            )}
                          </CButton>
                        </Link>
                      </CCol>
                      <CCol xs="8" className="text-right">
                        <Link to="/login">
                          <CButton color="link" className="px-0">
                            Remember password? Login
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  {/* <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div> */}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
